import React from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';

import { liveLocalized } from '@services/LocalizationService';
import controllerService from '@services/ControllerService';
import { UIBottomBar, UILabel, UITextButton } from '#components';
import { useCustomNavigate } from '#navigation/hooks/useCustomNavigate';
import { paths } from '#navigation/paths';
//@ts-ignore doesn`t see import
import Icon404 from '#assets/404.png';
//@ts-ignore doesn`t see import
import IconTonlabsBlack from '#assets/tonlabs-black.png';
import { common } from '#uikit/designCore/styles/comon';
import { container, margin } from '#uikit/designCore/styles';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { TypographyVariants } from '#uikit/designCore/constants/font';

import { errorCodes, errors, testIDs, ErrorScreenTestID } from './constants';

import './ErrorScreen.scss';

const ErrorScreen: React.FC = () => {
    const navigate = useCustomNavigate();

    const code = controllerService.errorCode;
    const error = code ? errors[errorCodes.pageNotfound] : null;
    const title = error?.title;
    const caption = controllerService.errorCaption;

    return (
        <div
            className={classnames('ErrorScreen', {
                narrow: controllerService.isNarrow,
                [container.fullWidthPadding]: controllerService.isNarrow,
            })}
        >
            <img className="ErrorScreen__img" src={Icon404} alt="error" />
            <div
                className={classnames(
                    'ErrorScreen__description',
                    controllerService.isNarrow ? margin.topGreat : margin.topEnormous
                )}
                data-testid={ErrorScreenTestID}
            >
                <UILabel color={ColorVariants.TextPrimary} role={TypographyVariants.TitleHuge}>
                    {title}
                </UILabel>
                <UILabel
                    color={ColorVariants.TextPrimary}
                    role={TypographyVariants.ParagraphText}
                    classNames={margin.topMedium}
                >
                    {caption}
                </UILabel>
            </div>
            <UITextButton
                testID={testIDs.BACK_TO_HOME_BUTTON}
                align={UITextButton.alignClassNames.center}
                title={liveLocalized.BackToHome}
                classNames={classnames(common.positionAbsolute, 'ErrorScreen__homeButton')}
                onPress={() => navigate(paths.main)}
            />
            <div className="ErrorScreen__bottomBarWrapper">
                <UIBottomBar
                    menuItems={[]}
                    isNarrow={controllerService.isNarrow}
                    copyRight={``}
                    copyRightIconPath={IconTonlabsBlack}
                />
            </div>
        </div>
    );
};

export default observer(ErrorScreen);
