import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { paths } from '#navigation/paths';
import { Blocks } from '#controllers/Lists/Blocks';
import { Block } from '#controllers/Details/Block';
import { Messages } from '#controllers/Lists/Messages';
import { Message } from '#controllers/Details/Message';
import { Transactions } from '#controllers/Lists/Transactions';
import { Transaction } from '#controllers/Details/Transaction';
import { ZeroState } from '#controllers/Details/ZeroState';
import { Accounts } from '#controllers/Lists/Accounts';
import { Account } from '#controllers/Details/Account';
import { Contracts } from '#controllers/Lists/Contracts';
import { Contract } from '#controllers/Details/Contract';
import { Validators } from '#controllers/Lists/Validators';
import { Validator } from '#controllers/Details/Validator';
import { Transfer } from '#controllers/Details/Transfer';

const Routing = () => (
    <Routes>
        <Route path="/" element={<Navigate to={paths.main} />} />
        <Route path="/*" element={<Navigate to={paths.main} />} />
        <Route path={paths.main} element={<Navigate to={paths.blocks.main} />} />
        <Route path={paths.blocks.main} element={<Blocks />} />
        <Route path={paths.blocks.details} element={<Block />} />
        <Route path={paths.messages.main} element={<Messages />} />
        <Route path={paths.messages.details} element={<Message />} />
        <Route path={paths.transactions.main} element={<Transactions />} />
        <Route path={paths.transactions.details} element={<Transaction />} />
        <Route path={paths.zeroState} element={<ZeroState />} />
        <Route path={paths.accounts.main} element={<Accounts />} />
        <Route path={paths.accounts.details} element={<Account />} />
        <Route path={paths.contracts.main} element={<Contracts />} />
        <Route path={paths.contracts.details} element={<Contract />} />
        <Route path={paths.validators.main} element={<Validators />} />
        <Route path={paths.validators.details} element={<Validator />} />
        <Route path={paths.transfers.details} element={<Transfer />} />
    </Routes>
);

export { Routing };
