const testHostEnv = { 'process.env.TEST_HOST': false };
const networkEnv = (key) => ({ 'process.env.NETWORK': JSON.stringify(key) });
const getKeyEnv = (key) => ({
    key,
    env: {
        ...networkEnv(key),
        ...testHostEnv,
    },
});

module.exports = {
    testnet: getKeyEnv('testnet'),
    net: getKeyEnv('net'),
    net2: getKeyEnv('net2'),
    common1net: getKeyEnv('common1'),
    common2net: getKeyEnv('common2'),
    privatenet: getKeyEnv('private'),
    mainnet: getKeyEnv('main'),
    cinet: getKeyEnv('cinet'),
    osnet: getKeyEnv('os'),
    rustnet: getKeyEnv('rustnet'),
    game0net: getKeyEnv('game0'),
    customnet: getKeyEnv('custom'),
    fldnet: getKeyEnv('fld'),
    SEnet: getKeyEnv('se'),
    vps21net: getKeyEnv('vps21'),
    vps23net: getKeyEnv('vps23'),
    vps25net: getKeyEnv('vps25'),
    bsnnet: getKeyEnv('bsn'),
    bsn2net: getKeyEnv('bsn2'),
    toldnet: getKeyEnv('told'),
    rfldnet: getKeyEnv('rfld'),
    goshnet: getKeyEnv('gosh'),
    smftnet: getKeyEnv('smft'),
    tonnet: getKeyEnv('ton'),
    gychainnet: getKeyEnv('gychain'),
    live: {
        url: 'https://ever.live',
        env: {
            ...testHostEnv,
        },
        output: 'web',
    },
};
