import React from 'react';
import classNames from 'classnames';

import { UITextButton } from '#components';
import { padding, height, container, width, common, flex, font, color } from '#uikit/designCore/styles';
import { TypographyVariants } from '#uikit/designCore/constants/font';
import { ColorVariants } from '#uikit/designCore/constants/color';

type MenuItem = {
    href: string;
    title: string;
    onPress?: () => void;
    componentClass?: any;
};

type CopyRightProps = {
    copyRight: string;
    copyRightIconPath?: string;
    isNarrow: boolean;
    isShort: boolean;
};

type MenuProps = {
    items: MenuItem[];
};

type Props = {
    copyRight: string;
    copyRightIconPath?: string;
    menuItems: MenuItem[];
    isNarrow: boolean;
};

const textStyleClassNames = classNames(font.tinyMedium, color.TextSecondary);

const Menu: React.FC<MenuProps> = ({ items }) => (
    <div className={classNames(container.centerLeft, common.displayFlex)}>
        {items.map(({ title, href, componentClass, onPress }, index) => {
            const Component = componentClass || UITextButton;
            return (
                <React.Fragment key={`bottom-bar-menu-item-${title}`}>
                    <Component
                        href={href}
                        title={title}
                        titleRole={TypographyVariants.ParagraphLabel}
                        titleColor={ColorVariants.TextSecondary}
                        onPress={onPress}
                    />
                    {index === items?.length - 1 ? null : (
                        <p className={textStyleClassNames}>&nbsp;&nbsp;·&nbsp;&nbsp;</p>
                    )}
                </React.Fragment>
            );
        })}
    </div>
);

const CopyRight: React.FC<CopyRightProps> = ({ copyRight, copyRightIconPath, isNarrow, isShort }) => {
    const isNarrowAndNotShort = isNarrow && !isShort;
    const copyRightText = isNarrowAndNotShort ? '' : copyRight;

    return (
        <div className={classNames(common.displayFlex, isShort ? flex.alignCenter : flex.alignEnd)}>
            <div className={textStyleClassNames}>
                <UITextButton
                    icon={isNarrowAndNotShort && copyRightIconPath}
                    title={copyRightText}
                    titleRole={TypographyVariants.ParagraphLabel}
                    titleColor={ColorVariants.TextSecondary}
                    onPress={() => window.open('https://everx.dev')}
                />
            </div>
        </div>
    );
};

const UIBottomBar: React.FC<Props> = ({ copyRight, copyRightIconPath, menuItems, isNarrow }) => (
    <div
        className={classNames(
            height.bigCell,
            padding.horizontal,
            width.full,
            common.displayFlex,
            flex.alignItemsCenter
        )}
    >
        {menuItems?.length ? (
            <div className={classNames(container.centerLeft, flex.flex1)}>{<Menu items={menuItems} />}</div>
        ) : null}
    </div>
);

export default UIBottomBar;
