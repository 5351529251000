import { NetKey } from 'types/types';
import EnvManager from '#helpers/EnvManager';
import { DEVNET_ENDPOINT, MAINNET_ENDPOINT, netKeys } from 'constants/constants';

const getNetworkCluster = (netKey: NetKey = EnvManager.getNetwork()) => {
    const {
        mainnet,
        testnet,
        net,
        net2,
        common1net,
        common2net,
        privatenet,
        cinet,
        osnet,
        rustnet,
        game0net,
        fldnet,
        customnet,
        SEnet,
        vps21net,
        vps23net,
        vps25net,
        bsnnet,
        bsn2net,
        toldnet,
        rfldnet,
        goshnet,
        gychainnet,
        smftnet,
        tonnet,
    } = netKeys;

    if (netKey === mainnet) return MAINNET_ENDPOINT;
    if (netKey === testnet) return 'testnet.ton.dev';
    if (netKey === net) return DEVNET_ENDPOINT;
    if (netKey === net2) return 'net2.ton.dev';
    if (netKey === common1net) return 'common1.ton.dev';
    if (netKey === common2net) return 'common2.ton.dev';
    if (netKey === privatenet) return 'private.ton.dev';
    if (netKey === cinet) return 'cinet.tonlabs.io';
    if (netKey === osnet) return 'os.ton.dev';
    if (netKey === rustnet) return 'dapp-test.itgold.io';
    if (netKey === game0net) return 'game0.ton.dev';
    if (netKey === customnet) return 'dapp-test.itgold.io';
    if (netKey === SEnet) return window.location.origin;
    if (netKey === vps21net) return 'vps21.ton.dev';
    if (netKey === vps23net) return 'vps23.ton.dev';
    if (netKey === vps25net) return 'vps25.ton.dev';
    if (netKey === bsnnet) return 'https://opbningxia.bsngate.com:18602/api/653c2aae1f154a8599e211e6152ef9e7/rest';
    if (netKey === bsn2net) return 'https://ton1.bsnbase.com/graphql';
    if (netKey === toldnet) return 'https://testnet.old.ton.dev';
    if (netKey === fldnet) return 'gql.custler.net';
    if (netKey === rfldnet) return 'rfld-dapp.itgold.io';
    // if (netKey === goshnet) return ['gra01.network.gosh.sh', 'bhs01.network.gosh.sh', 'eri01.network.gosh.sh'];
    if (netKey === goshnet) return 'gra01.network.gosh.sh';
    if (netKey === gychainnet) return 'https://opbningxia.bsngate.com:18602/api/653c2aae1f154a8599e211e6152ef9e7/rest';
    if (netKey === smftnet) return 'https://dapp-smft.itgold.io';
    if (netKey === tonnet) return 'ton-mainnet.evercloud.dev';
    return '';
};

export { getNetworkCluster };
