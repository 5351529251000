import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classnames from 'classnames';

//@ts-ignore
import iconHomeEver from '#assets/ico-ever/x-blue.svg';
//@ts-ignore
import iconHomeGosh from '#assets/ico-gosh/gosh.svg';
import { UIButton, LinkWithChild } from '#components';
import PopoverMenu from '#components/Popover/PopoverMenu/PopoverMenu';
import EnvManager from '#helpers/EnvManager';
import LinkManager from '#helpers/LinkManager';
import { getNetworkCluster } from '#helpers/index';
import { paths } from '#navigation/paths';
import { useCustomNavigate } from '#navigation/hooks/useCustomNavigate';
import { common, container, height, margin, padding } from '#uikit/designCore/styles';
import { ColorVariants } from '#uikit/designCore/constants/color';
import { netKeys } from 'constants/constants';
import { NetKey } from 'types/types';

import LangSwitcher from '../LangSwitcher';

const {
    mainnet,
    net,
    net2,
    cinet,
    privatenet,
    osnet,
    rustnet,
    customnet,
    fldnet,
    SEnet,
    vps21net,
    vps23net,
    vps25net,
    common1net,
    common2net,
    bsnnet,
    bsn2net,
    toldnet,
    rfldnet,
    goshnet,
    gychainnet,
    smftnet,
    tonnet,
} = netKeys;

export const leftMenuTestIDs = {
    networkMenu: 'networkMenu',
    activeNetwork: 'activeNetwork',
    networkLogo: 'networkLogo',
};

const networksInSelector = [];
const networksHidden = [
    SEnet,
];

const getSelectedIndex = () => {
    const currNetwork = EnvManager.getNetwork();
    const netIndexFromSelector = networksInSelector.findIndex((netKey) => netKey === currNetwork);

    // only one of hidden networks with last index can be visible at a time
    return netIndexFromSelector !== -1 ? netIndexFromSelector : networksInSelector.length;
};

const networkTitles: any = {
    [SEnet]: 'Acki Nacki',
};
// const testnetTitle = 'test.ton.org/testnet';

const LeftMenu = () => {
    const location = useLocation();
    const navigate = useCustomNavigate();

    const [shown, setShown] = useState(false);

    const currNetwork = EnvManager.getNetwork();
    const icon = EnvManager.isGoshnet() ? iconHomeGosh : iconHomeEver;

    const onPressNet = (netKey: NetKey) => {
        if (netKey !== currNetwork) LinkManager.openEverLive(netKey, false);
        else navigate(paths.main);
    };

    const getPressNet = (netKey: NetKey) => (netKey === currNetwork ? {} : { onPress: () => onPressNet(netKey) });

    const networkItem = (netKey: NetKey) => ({
        title: networkTitles[netKey] || getNetworkCluster(netKey),
        chosen: currNetwork === netKey,
        ...getPressNet(netKey),
    });

    let networksList;
    if (EnvManager.isGoshnet()) {
        networksList = [networkItem(goshnet)];
    }
    if (EnvManager.isTonnet()) {
        networksList = [networkItem(tonnet)];
    } else {
        networksList = networksInSelector.map((netKey) => networkItem(netKey));
        // testnet, game0net, game1net, game2net, game3net

        networksHidden.forEach((netKey: NetKey) => {
            if (currNetwork === netKey) networksList.push(networkItem(netKey));
        });
    }

    const getSelectedNetworkTitle = () => {
        const index = getSelectedIndex();
        return networksList[index]?.title;
    };

    const title = EnvManager.isGoshnet() ? 'Gosh' : EnvManager.isTonnet() ? 'TON Mainnet' : getSelectedNetworkTitle();

    const menuTrigger = (
        <UIButton
            classNames={padding.horizontalTiny}
            testID={leftMenuTestIDs.networkMenu}
            title={title}
            iconR={KeyboardArrowDownIcon}
            iconRClassNames={classnames('arrowDrop', { show: shown })}
            buttonColor={shown ? ColorVariants.TextAccent : ColorVariants.TextPrimary}
            buttonSize={UIButton.buttonSize.large}
        />
    );

    const image = (
        <img
            className={classnames(common.icon, margin.rightSmall)}
            data-testid={leftMenuTestIDs.networkLogo}
            src={icon}
            alt="label"
        />
    );

    const imageButton =
        location.pathname === paths.main ? image : <LinkWithChild navigationPath={paths.main}>{image}</LinkWithChild>;

    return (
        <div className={classnames(container.centerLeft, padding.leftDefault, height.defaultCell, common.displayFlex)}>
            {imageButton}
            <PopoverMenu
                menuItems={networksList}
                onShow={() => setShown(true)}
                onHide={() => setShown(false)}
                componentClassNames={padding.horizontal}
            >
                {menuTrigger}
            </PopoverMenu>
            <LangSwitcher />
        </div>
    );
};

export default observer(LeftMenu);
